<template>
  <b-modal
    ref="bmodal"
    hide-footer
    @show="resetModal"
    @hidden="resetModal"
  >
    <template #modal-title>
      <b-row>
        <b-col cols="12">
          <h4 class="mb-0">
            Update Category
          </h4>
        </b-col>
      </b-row>
    </template>
    <b-container>
      <form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12">
            <form-text-input
              v-model="name"
              label="Name"
              name="name"
              rules="required"
              type="text"
            />
          </b-col>
          <b-col cols="12">
            <form-text-input
              v-model="password"
              label="Password"
              name="password"
              rules="required"
              type="password"
            />
          </b-col>
          <b-col>
            <b-form-file
              v-model="file"
              placeholder="Cover Image"
              name="image"
              drop-placeholder="Drop file here..."
              accept="image/jpeg, image/png, image/jpg"
            />
          </b-col>
          <b-col
            cols="12"
            class="mt-4"
          >
            <div class="d-flex justify-content-between">
              <b-button
                class="mr-1"
                variant="secondary"
                @click="hide"
              >
                Cancel
              </b-button>
              <b-button
                variant="primary"
                type="submit"
              >
                Submit
              </b-button>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BButton, BCol, BContainer, BFormFile, BModal, BRow,
} from 'bootstrap-vue'
import FormTextInput from '@/components/FormTextInput.vue'

export default {
  components: {
    BButton,
    BFormFile,
    FormTextInput,
    BModal,
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      name: '',
      file: null,
      categoryId: null,
      image: null,
      password: '',
    }
  },
  emits: ['reset-modal', 'on-submit'],
  methods: {
    resetModal() {
      this.$emit('reset-modal')
    },
    onSubmit() {
      if (this.name === '') {
        return
      }
      const formValues = {
        objectId: this.categoryId,
        name: this.name,
        password: this.password,
        image: this.file ?? this.image,
      }
      this.$emit('on-submit', formValues)
      this.$refs.bmodal?.hide()
    },
    show(category) {
      this.name = category.name
      this.categoryId = category.objectId
      this.image = category.image
      this.password = category?.password
      this.$refs.bmodal?.show()
    },
    hide() {
      this.$refs.bmodal?.hide()
      this.name = ''
      this.file = null
      this.categoryId = null
    },
  },
}
</script>
