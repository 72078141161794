<script>
import {
  BButton, BCol, BDropdown, BDropdownItem, BImg, BRow, BTable,
} from 'bootstrap-vue'
import moment from 'moment/moment'
import { getCategories } from '@/api/category'
import TableHeader from '@/components/Genirecs/TableHeader.vue'
import CreateModal from '@/views/components/categories/CreateModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import uploadFile from '@/utils/upload'
import SelectResearches from '@/views/components/categories/SelectResearches.vue'
import UpdateModal from '@/views/components/categories/UpdateModal.vue'

export default {
  name: 'Categories',
  components: {
    SelectResearches,
    CreateModal,
    BButton,
    BRow,
    BImg,
    BCol,
    BDropdown,
    BDropdownItem,
    BTable,
    TableHeader,
    UpdateModal,
  },
  data() {
    return {
      categories: [],
      perPage: 50,
      pageOptions: [10, 50, 75, 100],
      totalRows: 1,
      currentPage: 1,
      researches: [],
      sortBy: '',
      selectedCategory: null,
      uploadProgress: 0,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'image', label: 'Image', sortable: false },
        'actions',
      ],
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async mounted() {
    this.categories = await getCategories()
    await this.getResearches()
  },
  methods: {
    showResearchModal(data) {
      this.selectedCategory = data
      this.$refs.researchModal.show()
    },
    showSubCategoryModal(data) {
      this.selectedCategory = data
      this.$refs.subCategoryModal.show()
    },
    async handleResearchSubmit(selectedResearchIds) {
      try {
        await Backendless.Data.of('ResearchCategories').setRelation(this.selectedCategory.item.objectId, 'researches', selectedResearchIds)
        if (this.selectedCategory.item.objectId !== 'C6E64507-FF1C-4AAB-879B-6540BA1BE17A') {
          await Backendless.Data.of('ResearchCategories').deleteRelation('C6E64507-FF1C-4AAB-879B-6540BA1BE17A', 'researches', selectedResearchIds)
        }
        this.categories = await getCategories()
      } catch (e) {
        console.log(e)
      }
    },
    async handleSubCategorySubmit(selectedSubCategories) {
      try {
        await Backendless.Data.of('ResearchCategories').setRelation(this.selectedCategory.item.objectId, 'subCategories', selectedSubCategories)
        this.categories = await getCategories()
      } catch (e) {
        console.log(e)
      }
    },
    makeId(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      let counter = 0
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        )
        counter += 1
      }

      const currentTimestamp = moment(new Date()).format(
        'MM_DD_YYYY_h_mm_ss_SSS',
      )
      return result + currentTimestamp
    },
    getRows(perPage, page) {
    },
    changePage(page) {
    },
    async getResearches() {
      const queryBuilder = Backendless.DataQueryBuilder.create()
      queryBuilder
        .setPageSize(100)
        .setSortBy(['created DESC'])
        .excludeProperties(['*'])
        .addRelated('localizations')

      const query = Backendless.Data.of('Researches')
      const result = await query.find(queryBuilder)
      this.researches = result.map(research => ({ id: research.objectId, name: research.localizations[0].title }))
    },
    async onCreate(data) {
      this.mytoaster = this.$toast({
        component: ToastificationContent,
        props: {
          dismissible: true,
          title: 'Uploading file',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
      const created = await uploadFile(
        data.image,
        `/myfiles/${this.makeId(7)}`,
        this.handleProgress,
      )
      data.image = created.data.fileURL
      await Backendless.Data.of('ResearchCategories').save(data)
      this.categories = await getCategories
    },
    onReset() {
    },

    showConfirmDeleteQuestion(category) {
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete Category ${category.item.name} .`,
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            const that = this
            Backendless.Data.of('ResearchCategories')
              .remove({ objectId: category.item.objectId })
              .then(async () => {
                // The object was deleted from the Parse Cloud.
                that.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Category Deleted Successfully',
                    icon: 'BellIcon',
                    text: category.item.name,
                    variant: 'success',
                  },
                })
                this.categories = await getCategories()
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
    },
    async onUpdate(data) {
      this.mytoaster = this.$toast({
        component: ToastificationContent,
        props: {
          dismissible: true,
          title: 'Uploading file',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
      if (data.image.name != null) {
        const updated = await uploadFile(
          data.image,
          `/myfiles/${this.makeId(7)}`,
          this.handleProgress,
        )
        data.image = updated.data.fileURL
      }
      await Backendless.Data.of('ResearchCategories').save(data)
      this.categories = await getCategories()
    },
    showUpdateModal(category) {
      this.$refs['update-category'].show(category)
    },
  },
}
</script>

<template>
  <div>
    <TableHeader
      :per-page="perPage"
      :page-options="pageOptions"
      :current-page="currentPage"
      :sort-by="sortBy"
      :sort-options="sortOptions"
      :filter="filter"
      :sort-desc="sortDesc"
      :enable-export="true"
      @reset:filter="filter = ''"
      @update:current-page="(page) => getRows(perPage, page)"
      @update:per-page="(pp) => getRows(pp, currentPage)"
      @update:sort-by="(sb) => { sortBy = sb }"
      @update:sort-desc="(sd) => { sortDesc = sd }"
      @update:filter="(f) => { filter = f }"
    >
      <b-col
        md="auto"
        sm="2"
        class="my-1 mr-auto"
      >
        <b-button @click="() => {this.$refs['add-category'].show()}">
          Create
        </b-button>
      </b-col>
    </TableHeader>
    <b-row>
      <b-col
        sm="12"
        class="p-relative"
      >
        <b-progress
          v-if="uploadProgress > 0"
          class="questions__progress"
          :value="uploadProgress"
          :max="100"
        />
      </b-col>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="0"
          :current-page="currentPage"
          :items="categories"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="() => {currentPage = 1}"
        >
          <template #cell(image)="data">
            <b-img
              left
              class="mb-1 mb-sm-0"
              height="40"
              :src="data.value"
              alt="Left image')"
            />
          </template>
          <template #cell(actions)="data">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="showConfirmDeleteQuestion(data)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showResearchModal(data)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Select Research Studies</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showSubCategoryModal(data)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Select Sub Categories</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showUpdateModal(data.item)">
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>

        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1">
        {{ currentPage !== Math.ceil(totalRows / perPage) ? perPage : totalRows % perPage }} - {{ totalRows }}
      </b-col>
      <b-col cols="11">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
          @input="(page) => changePage(page)"
        />
      </b-col>
    </b-row>
    <create-modal
      ref="add-category"
      @on-submit="onCreate"
      @reset-modal="onReset"
    />
    <select-researches
      ref="researchModal"
      :researches="researches"
      :category="selectedCategory"
      type="research"
      @on-submit="handleResearchSubmit"
    />
    <select-researches
      ref="subCategoryModal"
      :researches="categories.map(category=>({id:category.objectId ,name:category.name}))"
      :category="selectedCategory"
      type="subCategory"
      @on-submit="handleSubCategorySubmit"
    />
    <update-modal
      ref="update-category"
      @on-submit="onUpdate"
      @reset-modal="onReset"
    />
  </div>

</template>

<style scoped lang="scss">

</style>
