export async function getCategories(page = 1, pageSize = 100) {
  const queryBuilder = Backendless.DataQueryBuilder.create()
  queryBuilder
    .setPageSize(pageSize)
    .setOffset(page - 1)
    .setRelationsDepth(2)
    .setRelationsPageSize(100)
    .setSortBy(['created DESC'])
  const query = Backendless.Data.of('ResearchCategories')
  const result = await query
    .find(queryBuilder)
  return result
}
