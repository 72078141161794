import axios from 'axios'
import { backendlessApiKey, backendlessAppId, backendlessHost } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default async (file, path, onProgress) => {
  const formData = new FormData()

  console.log('file', file)
  formData.append('file', file)
  const fileName = encodeURIComponent(file.name)

  return axios({
    method: 'post',
    url: `${backendlessHost}/${backendlessAppId}/${backendlessApiKey}/files/${path}/${fileName}`,
    data: formData,
    headers: {
      'user-token': useJwt.getToken(), // Assuming useJwt.getToken() is synchronous
    },
    onUploadProgress: onProgress,
  })
}

export function handleProgress(progressEvent) {
  this.uploadProgress = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
  if (this.uploadProgress === 100) {
    this.uploadProgress = 0
    this.mytoaster = this.$toast({
      component: ToastificationContent,
      props: {
        dismissible: true,
        title: 'File uploaded successfully',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
  }
}
