<script>
import {
  BButton, BCol, BFormCheckbox, BFormCheckboxGroup, BFormInput, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox, BRow, BCol, BFormCheckboxGroup, BFormInput, BButton,
  },
  props: {
    researches: {
      type: Array,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedResearchIds: [],
      searchQuery: '',
    }
  },
  emits: ['on-submit'],
  computed: {
    filteredResearches() {
      return this.researches.filter(research => research.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
    },
  },
  watch: {
    category: {
      handler() {
        this.updateSelectedResearchIds()
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSelectedResearchIds()
  },
  methods: {
    updateSelectedResearchIds() {
      if (this.type === 'research') {
        this.selectedResearchIds = this.category.item.researches.map(research => research.objectId)
      } else {
        this.selectedResearchIds = this.category.item.subCategories.map(subCategory => subCategory.objectId)
      }
    },
    onSubmit() {
      this.$emit('on-submit', this.selectedResearchIds)
      this.hide()
    },
    show() {
      this.$refs.bmodal.show()
    },
    hide() {
      this.$refs.bmodal.hide()
    },
  },
}
</script>

<template>
  <b-modal
    ref="bmodal"
    hide-footer
    title="Select Research Studies"
    dialog-class="modal-50vh"
  >
    <b-container>
      <form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search by name"
                class="mb-3"
              />
              <b-form-checkbox-group
                v-model="selectedResearchIds"
                stacked
              >
                <b-form-checkbox
                  v-for="research in filteredResearches"
                  :key="research.id"
                  :value="research.id"
                  :disabled="category.item.objectId === research.id"
                >
                  {{ research.name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-4"
          >
            <div class="d-flex justify-content-between">
              <b-button
                class="mr-1"
                variant="secondary"
                @click="hide"
              >
                Cancel
              </b-button>
              <b-button
                variant="primary"
                type="submit"
              >
                Submit
              </b-button>

            </div>
          </b-col>
        </b-row>
      </form>
    </b-container>
  </b-modal>
</template>

<style>
.modal-50vh .modal-content {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
